import React, { useEffect } from "react";
import qs from "query-string";
import orderBy from "lodash/orderBy";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { useQuery } from "react-query";

import Button from "@ui-kit/Button";

import Dialog from "@ui-kit/Dialog";

import { createCss } from "./styles";
import { ROUTES } from "@constants";
import { API } from "@services/api";
import { QUERIES } from "@constants/queries";
import { useAppStore } from "@store/AppStore";
import { ListItem } from "./ListItem";
import { useRouter } from "@hooks/useRouter";
import { useIsMounted } from "@hooks/useIsMounted";

const ChildrenList: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { profileClosed } = qs.parse(search);
  const [{ realLocation }] = useAppStore();
  const { updateQueryParams } = useRouter();
  const isMounted = useIsMounted();
  const returnPath =
    realLocation.current.pathname + realLocation.current.search;

  const {
    data: children,
    isLoading,
    isFetchedAfterMount,
    isPreviousData,
  } = useQuery(QUERIES.CHILDREN_LIST, API.children.getList, {
    refetchOnMount: !profileClosed ? "always" : true,
  });

  const css = createCss();

  const onClose = () => history.replace(returnPath);

  useEffect(() => {
    setTimeout(() => {
      if (profileClosed && isMounted()) {
        updateQueryParams({ profileClosed: undefined }, true);
      }
    });
  }, []);

  if (isFetchedAfterMount && !children?.length) {
    return <Redirect to={ROUTES.CHILDREN_LIST + "/new"} />;
  }
  if (profileClosed && !children?.length) {
    return <Redirect to={returnPath} />;
  }

  function renderChildren() {
    if (!children) {
      return null;
    }

    return orderBy(children.slice(), "birthday", "desc").map((child) => (
      <ListItem key={child.id} child={child} />
    ));
  }

  return (
    <Dialog open onClose={onClose} title="Child profile(s)" loading={isLoading}>
      <form data-test="children-profiles" css={css.container}>
        <div css={css.header}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            name="create-new"
            link={{
              pathname: `${ROUTES.CHILDREN_LIST}/new`,
            }}
          >
            Add another
          </Button>
        </div>
        <div>{renderChildren()}</div>
      </form>
    </Dialog>
  );
};

export default ChildrenList;
