import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";

type Param = string | string[] | number | number[] | undefined;

export function useRouter() {
  const history = useHistory();
  const { search } = useLocation();

  const updateQueryParams = useCallback(
    (params: { [key: string]: Param }, replace = false) => {
      const parsed = qs.parse(search);
      const method = replace ? "replace" : "push";
      history[method]({
        search: qs.stringify({
          ...parsed,
          ...params,
        }),
      });
    },
    [search, history],
  );

  return { updateQueryParams };
}
