import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    minWidth: 300,
  },

  actions: {
    display: "flex",
    marginTop: 32,
    justifyContent: "flex-end",

    "& button + button": {
      marginLeft: 8,
    },
  },
});
