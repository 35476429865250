import React from "react";

import Button from "@ui-kit/Button";
import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";

import { ConfirmDialogProps } from "./types";
import { createCss } from "./styles";

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  onClose,
  title,
  confirm,
}) => {
  const css = createCss();

  return (
    <Dialog open onClose={onClose}>
      <div css={css.root}>
        <Typography bolded paragraph>
          {title}
        </Typography>

        <div css={css.actions}>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirm.onClick}
            variant="contained"
            color="error"
            name="confirm"
          >
            {confirm.label}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
