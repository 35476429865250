import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    cursor: "pointer",
    display: "flex",
    padding: "10px 0",
    position: "relative",

    "&:last-child": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }),

  deleteIcon: (theme) => ({
    cursor: "pointer",
    fill: theme.palette.error.main,
    position: "absolute",
    right: 5,
    top: 5,
  }),
});
