import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import DeleteIcon from "@material-ui/icons/Delete";

import { ListItemProps } from "./types";
import { createCss } from "./styles";
import { getAge } from "@helpers/get-age-from-date";
import { ROUTES } from "@constants";
import { Models, API } from "@services/api";
import { getChildFullName } from "@selectors";
import Typography from "@ui-kit/Typography";
import { must } from "@utils/must";
import { QUERIES } from "@constants/queries";
import { ConfirmDialog } from "@components/ConfirmDialog";

export const ListItem: React.FC<ListItemProps> = ({ child }) => {
  const history = useHistory();

  const queryClient = useQueryClient();
  const { mutate: deleteChild } = useMutation(
    (id: number) => API.children.deleteOne({ id }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(QUERIES.CHILDREN_LIST);
      },
    },
  );

  const [toRemoveId, setToRemoveId] = useState<number | null>(null);
  const css = createCss();

  const goToProfile = (id: number) => {
    history.push(ROUTES.CHILDREN_LIST + `/${id}`);
  };

  const handleDeleteProfile = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    child: Models.Child,
  ) => {
    event.stopPropagation();
    setToRemoveId(must(child.id));
  };

  return (
    <>
      {toRemoveId && (
        <ConfirmDialog
          title={`Remove ${child.firstName} ${child.lastName}?`}
          onClose={() => setToRemoveId(null)}
          confirm={{
            label: "Remove",
            onClick: () => {
              deleteChild(toRemoveId);
              setToRemoveId(null);
            },
          }}
        />
      )}

      <div
        css={css.root}
        onClick={() => goToProfile(must(child.id))}
        data-test="child-profile"
      >
        <div>
          <Typography gutterBottom>
            <b>Name:</b> {getChildFullName(child)}
          </Typography>
          <Typography gutterBottom>
            <b>Age:</b> {getAge(child.birthday)?.str}
          </Typography>
          <Typography>
            <span className="g-link">Show more</span>
          </Typography>
        </div>
        <DeleteIcon
          css={css.deleteIcon}
          onClick={(event) => handleDeleteProfile(event, child)}
          data-test="remove-btn"
        />
      </div>
    </>
  );
};
